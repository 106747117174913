

// font-family: "Oswald", sans-serif;
// font-family: "Nunito", sans-serif;

@use 'variables-dark';
@use 'header-dark';

@keyframes gradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
}

body {
    background-color: rgb(0, 0, 0);
    background-image: 
        radial-gradient(at 81% 65%, rgb(0, 0, 7) 0px, transparent 50%), 
        radial-gradient(at 95% 99%, rgb(0, 7, 0) 0px, transparent 50%), 
        radial-gradient(at 48% 26%, rgb(7, 0, 0) 0px, transparent 50%), 
        radial-gradient(at 75% 26%, rgb(0, 7, 7) 0px, transparent 50%);
    background-size: 500% 500%;
    animation: gradient 9s ease infinite;
    height: 100vh;
    // color: hsla(0, 0%, 90%, 1); 
}

.container {
    max-width: 1024px;
    margin: 0 auto;
}

.title {
    font-family: variables-dark.$fontTitle;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -.5px;
    color: hsla(0, 0%, 90%, 1); 
}

.text {
    color: variables-dark.$textWhite;
    font-family: variables-dark.$fontText;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
}

.text--gray {
    font-family: variables-dark.$fontTitle;
    font-size: 18px;
    font-weight: 400;
    color: variables-dark.$textLink;
}

.text--gray-small {
    font-family: variables-dark.$fontTitle;
    font-size: 16px;
    font-weight: 400;
    color: variables-dark.$textLink;
}

.blur {
    display: none;
    opacity: 0;
    position: fixed;

    &--active {
        display: block;
        opacity: 100;
        // width: 100vw;
        height: 200vh;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        backdrop-filter: blur(16px);
        z-index: 1;
    }
}

//video portfolio
.portfolio-video {
    pointer-events: none;
    position: absolute;
    width: 95%;
    border-radius: 40px;
    box-shadow: 0 0 16px 0px #ffffff36;
    // transition: opacity .2s ease;
    
    &--show {
        display: block;
        opacity: 100;
        pointer-events: auto;
    }

    &--show-mobile {
        opacity: 100;
        position: fixed;
        top: 32vh;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 2;

        @media (min-width: 768px) and (max-width: 990px) {
            top: 10vh;
        }
    }
}

.deploy-mobile {
    position: fixed;
    top: 65vh;
    left: 38vw;
    color: #000000;
    z-index: 3;
    background-color: #fff;
    border-radius: 24px;
    padding: 12px 24px;
    box-shadow: 0 0 16px 0px #ffffff36;

    @media (min-width: 768px) and (max-width: 990px) {
        top: 58vh;
        left: 45vw;
    }
}

.close {
    position: fixed;
    top: 8;
    right: 4;
    z-index: 2;
    
    &__button {
        background-color: #ffffffb7;
        font-family: sans-serif;
        font-size: 32px;
        color: #000000;
        border-radius: 50%;
        padding: 2px 12px 5px ;
        width: auto;
        height: auto;
        cursor: pointer;
    }
}

.opacity-none {
    display: none;
    opacity: 0;
}

.disable-link {
    pointer-events: none;
}