.header {
  grid-template-columns: 35% auto;
  display: grid;
}

.header__menu {
  flex-direction: column;
  align-content: center;
  margin: 26.5vh auto;
  padding-left: 8vw;
  display: flex;
}

.header__menu__item {
  cursor: pointer;
  grid-template-columns: 80% 10%;
  width: 300px;
  margin-bottom: 8px;
  padding: 12px 24px;
  transition: transform .2s;
  display: grid;
}

.header__menu__item__year {
  text-align: right;
  pointer-events: auto;
  transition: transform .2s;
}

.header__menu__item__year:hover {
  color: #000;
  border-radius: 30%;
  transform: scale(1.5);
}

.header__menu__item:hover {
  background-color: #fafafa;
  border-radius: 24px;
  transform: scale(1.05);
  box-shadow: 0 0 16px #ffffff2a;
}

.header__menu__item:hover .text {
  color: #000;
}

.header__menu .what {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.header__menu .what span {
  opacity: .3;
}

.header__menu .what h3 {
  opacity: .6;
  margin-top: 16px;
  font-size: 16px;
}

.header__about {
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 20vh 10vw 20vh 6vw;
  display: flex;
  position: sticky;
  top: 0%;
  bottom: 0%;
  left: auto;
}

.header__about__image {
  margin-top: 16px;
}

.header__about__image-bg {
  opacity: .25;
  z-index: -1;
  border-radius: 50%;
  width: 100%;
  max-width: 260px;
  margin-bottom: 32px;
  position: absolute;
  transform: scale(1.15);
}

.header__about__image-perfil {
  border-radius: 50%;
  width: 100%;
  max-width: 260px;
  margin-bottom: 32px;
}

.header__about__text span {
  background: linear-gradient(214deg, #3a1c71, #d76d77, #cf8e62);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.header__about__links {
  justify-content: flex-start;
  gap: 12px;
  margin-top: 16px;
  display: flex;
}

.header__about__links a:hover {
  color: #ffffff63;
}

@media (width <= 767px) {
  .header {
    flex-direction: column-reverse;
    display: flex;
  }

  .header__about {
    align-items: center;
    height: 100%;
    padding-bottom: 0;
    position: static;
  }

  .header__menu {
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .header__menu__item {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }

  .header__menu__item:hover .text {
    pointer-events: none;
  }
}

@media (width >= 768px) and (width <= 990px) {
  .header__about {
    padding-left: 12vw;
  }

  .header__menu__item:hover .text {
    pointer-events: none;
  }
}

@keyframes gradient {
  0% {
    background-position: 0%;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0%;
  }
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style: none;
}

body {
  background-color: #000;
  background-image: radial-gradient(at 81% 65%, #000007 0, #0000 50%), radial-gradient(at 95% 99%, #000700 0, #0000 50%), radial-gradient(at 48% 26%, #070000 0, #0000 50%), radial-gradient(at 75% 26%, #000707 0, #0000 50%);
  background-size: 500% 500%;
  height: 100vh;
  animation: 9s infinite gradient;
}

.container {
  max-width: 1024px;
  margin: 0 auto;
}

.title {
  letter-spacing: -.5px;
  color: #e6e6e6;
  font-family: Oswaldo, sans-serif;
  font-size: 24px;
  line-height: 30px;
}

.text {
  color: #fff;
  font-family: Nunito, sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.text--gray {
  color: #ffffffbe;
  font-family: Oswaldo, sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.text--gray-small {
  color: #ffffffbe;
  font-family: Oswaldo, sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.blur {
  opacity: 0;
  display: none;
  position: fixed;
}

.blur--active {
  opacity: 100;
  backdrop-filter: blur(16px);
  z-index: 1;
  height: 200vh;
  display: block;
  inset: 0;
}

.portfolio-video {
  pointer-events: none;
  border-radius: 40px;
  width: 95%;
  position: absolute;
  box-shadow: 0 0 16px #ffffff36;
}

.portfolio-video--show {
  opacity: 100;
  pointer-events: auto;
  display: block;
}

.portfolio-video--show-mobile {
  opacity: 100;
  z-index: 2;
  width: 100%;
  position: fixed;
  inset: 32vh 0 0;
}

@media (width >= 768px) and (width <= 990px) {
  .portfolio-video--show-mobile {
    top: 10vh;
  }
}

.deploy-mobile {
  color: #000;
  z-index: 3;
  background-color: #fff;
  border-radius: 24px;
  padding: 12px 24px;
  position: fixed;
  top: 65vh;
  left: 38vw;
  box-shadow: 0 0 16px #ffffff36;
}

@media (width >= 768px) and (width <= 990px) {
  .deploy-mobile {
    top: 58vh;
    left: 45vw;
  }
}

.close {
  z-index: 2;
  position: fixed;
  top: 8px;
  right: 4px;
}

.close__button {
  color: #000;
  cursor: pointer;
  background-color: #ffffffb7;
  border-radius: 50%;
  width: auto;
  height: auto;
  padding: 2px 12px 5px;
  font-family: sans-serif;
  font-size: 32px;
}

.opacity-none {
  opacity: 0;
  display: none;
}

.disable-link {
  pointer-events: none;
}

/*# sourceMappingURL=index.f8ca9eb1.css.map */
