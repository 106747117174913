@use 'variables-dark';

.header {
    display: grid;
    grid-template-columns: 35% auto;

    &__menu {
        // height: 250vh; //what
        display: flex;
        flex-direction: column;
        align-content: center;
        // margin: 26vh auto;
        margin: 26.5vh auto;

        padding-left: 8vw;
        
        &__item {
            display: grid;
            grid-template-columns: 80% 10%;
            padding: 12px 24px;
            margin-bottom: 8px;
            width: 300px;
            transition: transform 0.2s ease;
            cursor: pointer;
            
            &__year {
                text-align: right;
                pointer-events: auto;
                transition: transform 0.2s ease;

                &:hover{
                    border-radius: 30%;
                    transform: scale(1.5);
                    color: #000;
                }
            }
            
            //hover button portfolio
            &:hover {
                background-color: #fafafa;
                border-radius: 24px;
                box-shadow: 0 0 16px 0px #ffffff2a;
                transform: scale(1.05);

                .text {
                    color: #000;
                }
            }
        }

        .what {
            span {
                opacity: 30%;
            }
            display: flex;
            flex-direction: column;
            align-items: center;

            h3 {
                margin-top: 16px;
                font-size: 16px;
                opacity: 60%;
            }

            // &__image {
            //     margin-top: 16px;
            //     margin-bottom: 90px;
            //     max-width: 300px;
            //     width: 100%;
            //     background-image: 45;
            //     border-radius: 50%;
            // }
        }
    }

    &__about {
        position: sticky;
        left: auto;
        top: 0%;
        bottom: 0%;
        height: 100vh;
        padding: 20vh 10vw 20vh 6vw;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;

        &__image {
            margin-top: 16px;
            // display: block;
            &-bg {
                opacity: 25%;
                z-index: -1;
                position: absolute;
                max-width: 260px;
                width: 100%;
                border-radius: 50%;
                margin-bottom: 32px;
                transform: scale(1.15);
            }

            &-perfil {
                max-width: 260px;
                width: 100%;
                border-radius: 50%;
                margin-bottom: 32px;
            }
        }

        //gradient about
        &__text {
            span {
                background: linear-gradient(214deg, #3a1c71, #d76d77, #cf8e62);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
        }
        
        &__links {
            display: flex;
            justify-content: flex-start;
            gap: 12px;
            margin-top: 16px;
            
            a:hover {
                color: variables-dark.$textLinkHover;
            }
        }
    }

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        flex-direction: column-reverse;
        
        &__about {
            position: static;
            height: 100%;
            align-items: center;
            padding-bottom: 0;
        }

        &__menu {
            margin: 0;
            padding: 0;
            align-items: center;

            &__item {
                display: flex;
                justify-content: space-between;
                width: 100%;

                &:hover {
                    .text {
                        pointer-events: none;
                    }
                }
            }
        }
    }

    @media (min-width: 768px) and (max-width: 990px) {
        &__about {
            padding-left: 12vw;
        }
        
        &__menu {
            &__item {
                &:hover {
                    .text {
                        pointer-events: none;
                    }
                }
            }
        }
    }
}